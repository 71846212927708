<template>
    <btn app @click="$emit('click')" color="rally" class="transition-3d-hover">
        <slot>
            <template v-if="end">
                Quit
                <mdi> exit_to_app </mdi>
            </template>
            <template v-else>
                {{text}}
                <i class="fas fa-caret-right ml-1"> </i>
            </template>
        </slot>
    </btn>
</template>

<script>
export default {
    props: {
        end: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "Next",
        }
    },
};
</script>
