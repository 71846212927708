<template>
    <div class="container space-top-1 space-bottom-1">
        <div class="d-flex justify-content-center">
            <div class="dashboard-page-container">
                <slot> </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>


<style>

.test {

    width: 660px !important;

}

</style>
