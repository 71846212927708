<template>
  <div class="view-app">
    <interview-prep
      @cancelInterview="preparing = false"
      :year="year"
      v-if="preparing"
    ></interview-prep>
    <template v-else>
      <sidebar-view>
        <dashboard-container>
          <div class="card">
            <div class="card-body pb-6">
              <div class="card-title border-bottom pb-4 mb-5">
                <div class="h2 text-center">
                  Your Tax Returns
                </div>
                <p class="m-0 p-0 text-center app-lead">
                  File your tax return by selecting the year you're filing for
                  below. As you answer our questions, we'll automatically
                  determine which forms you're required to file.
                </p>
              </div>
              <div v-if="loading">
                <div class="d-flex justify-content-center py-8">
                  <loading :size="55" color="primary" />
                </div>
              </div>

              <div v-else>
                <div
                  v-for="tr in taxReturns"
                  :key="tr.id"
                  class="d-flex justify-content-center mb-2"
                >
                  <div class="col-12 col-md-9 col-lg-8">
                    <div class="border p-3 rounded-lg year_token mx-auto mb-3">
                      <div class="p-1">
                        <div class="d-flex justify-content-between">
                          <h2>
                            {{ tr.year }}
                          </h2>
                          <badge v-if="tr.is_completed" color="success">
                            <i class="fas fa-check mr-1"></i>
                            Completed
                          </badge>
                          <badge v-else-if="tr.first_name" color="info">
                            <mdi>hourglass_empty</mdi>
                            In Progress
                          </badge>
                        </div>
                        <p class="lead">
                          <span v-if="tr.disabled">
                            {{ tr.year }} Tax Filing Coming Soon
                          </span>
                          <span v-else-if="tr.first_name">
                            {{ tr.first_name }}
                            {{ tr.last_name }}
                          </span>
                          <span v-else>
                            Start Filing Your
                            {{ tr.year }}
                            Tax Return
                          </span>
                        </p>
                      </div>
                      <div class="row px-3">
                        <template v-if="tr.is_completed">
                          <div class="col-12 col-md p-0 pr-md-1 mb-2 mb-md-0">
                            <btn
                              app
                              block
                              @click="viewInterview(tr.id)"
                              color="info"
                            >
                              View
                              <mdi>
                                remove_red_eye
                              </mdi>
                            </btn>
                          </div>
                          <div class="col-12 col-md p-0 pl-md-1">
                            <btn app block @click="openDownloadMenu(tr)">
                              Download
                              <mdi>download</mdi>
                            </btn>
                          </div>
                        </template>
                        <template v-else>
                          <btn
                            :disabled="tr.disabled"
                            app
                            block
                            @click="startInterview(tr)"
                            color="primary"
                          >
                            <template v-if="tr.is_started">
                              Continue Filing
                              <mdi>
                                arrow_forward
                              </mdi>
                            </template>
                            <template v-else>
                              Get Started
                              <mdi>
                                arrow_forward
                              </mdi>
                            </template>
                          </btn>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dashboard-container>
      </sidebar-view>
    </template>

    <modal v-model="downloadModal">
      <template v-slot:title>
        Download {{ downloadModalTr.year }} Forms
      </template>
      <template v-slot:body>
        <btn
          :loading="ret.loading"
          app
          block
          color="info"
          v-for="ret in availableReturns"
          class="mb-3"
          :key="ret.name"
          @click="download(ret, null)"
        >
          {{ ret.name }}
        </btn>
      </template>
    </modal>
  </div>
</template>

<script>
import sidebarView from "./sidebar/sidebar";
import interviewPrep from "@/views/interview/interview_prep";
import dashboardContainer from "./dashboard_container";
import sendConversion from "@/views/send_conversion";

export default {
  data: () => ({
    taxReturns: [],
    newPage: null,
    preparing: false,
    loading: false,
    downloadModal: false,
    downloadModalTr: null,
    availableReturns: [],
    year: null,

    showModal: false
  }),

  props: {
    source: String
  },
  methods: {
    viewInterview(id) {
      this.$store.commit("setTrId", id);
      this.$router.push({ name: "Interview" });
    },
    startInterview(tr) {
      this.preparing = true;
      this.year = tr.year;
      this.$store.commit("setTrId", tr.id);
    },
    openDownloadMenu(tr) {
      if (
        this.downloadModalTr &&
        this.availableReturns.length != 1 &&
        this.downloadModalTr.id == tr.id
      ) {
        this.downloadModal = true;
        return;
      }

      for (var i = 0; i < this.taxReturns.length; i++) {
        var _tr = this.taxReturns[i];
        if (_tr.id == tr.id) {
          _tr.loading = true;
        }
      }

      this.downloadModalTr = tr;
      this.$store.getters.client
        .get("/return-types/", { params: { id: tr.id } })
        .then(response => {
          this.availableReturns = [];
          var ret = {
            name: response.data.context.returns[0]
          };
          if (response.data.context.returns.length == 1) {
            this.download(ret);
            return;
          }

          for (var i = 0; i < response.data.context.returns.length; i++) {
            this.availableReturns.push({
              name: response.data.context.returns[i],
              loading: false
            });
          }
          this.downloadModal = true;
          tr.loading = false;
        });
    },
    download(ret) {
      var type = ret.name;
      ret.loading = true;
      var index;
      var tr = this.downloadModalTr;
      for (var i = 0; i < this.taxReturns.length; i++) {
        var _tr = this.taxReturns[i];
        if (_tr.id == this.downloadModalTr.id) {
          index = i;
        }
      }
      return this.$store.getters.client
        .get(`gen-pdfs/${tr.id}/`, {
          params: {
            return_type: type
          },
          responseType: "arraybuffer"
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);

          var fileName;
          if (type == "Family 8843 Forms") {
            fileName = `Thriftax Family 8843 Forms ${tr.year}.pdf`;
          } else {
            fileName = `Thriftax ${type} Tax Forms ${tr.year}.pdf`;
          }
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          ret.loading = false;
          this.taxReturns[index].loading = false;
        });
    }
  },
  created() {
    this.loading = true;
    this.$store.getters.client.get("/tax-returns/").then(response => {
      for (var tr of response.data) {
        this.taxReturns.push({
          ...tr,
          loading: false
        });
      }

      this.loading = false;
    });

    sendConversion({ address: "AW-417237183/sW-lCOHFt_cBEL-R-sYB" });
  },
  components: {
    dashboardContainer,
    sidebarView,
    interviewPrep
  }
};
</script>
