<template>
    <btn @click="$emit('click')" app color="info" class="transition-3d-hover">
        <slot>
            <template>
                <i class="fas fa-caret-left mr-1"> </i>
                Back
            </template>
        </slot>
    </btn>
</template>

<script>
export default {
};
</script>
