<template>
    <div class="container space-top-1">
        <div class="card" data-aos="fade-down">
            <div class="card-body p-4">
                <h2 class="mb-0">
                    You are about to begin your tax return filing with Thriftax
                </h2>
                <p>
                    We recommend that you prepare the items listed on the
                    checklist below in advance and have them handy while filing:
                </p>

                <div class="px-md-3">
                    <div
                        class="media mb-3 font-size-base"
                        v-for="(point, index) in checklist"
                        :key="index"
                    >
                        <span
                            class="icon icon-xs icon-soft-primary icon-circle mr-3"
                        >
                            <i class="fas fa-check"></i>
                        </span>
                        <div class="media-body text-dark font-size-base">
                            <dyn-render :textProps="{ year }" :html="point">
                            </dyn-render>
                        </div>
                    </div>
                </div>
                <p>
                    It is advised to complete your filing in one single session.
                    However, you may exit the process and return at any time
                    convenient for you. Your data will automatically be saved
                    for each page you complete.
                </p>
            </div>
        </div>
        <div class="d-flex justify-content-between mt-4">
            <back-button @click="$emit('cancelInterview')" />
            <next-button @click="$router.push({ name: 'Interview' })" />
        </div>
    </div>
</template>

<script>
import AOS from 'aos';
import nextButton from '@/components/interview/next-button';
import backButton from '@/components/interview/back-button';

export default {
    components: { nextButton, backButton },
    data() {
        return {
            checklist: [
                `Passport`,
                `Visa and Immigration Status forms (I-20 for F visa holders or DS-2019 for J visa holders)`,
                `Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN). If you don’t have one, that's okay – you may not need it. We'll let you know.`,
                `Entry and exit dates of your visits to the US over the past 6 years, including the visa types you entered with, if any. You may rely on form I-94 to view your visits to the U.S. over the last 5 years – check your visits in the <a target="_blank" href="https://i94.cbp.dhs.gov/I94/#/history-search" >Department of Homeland Security Website <i class="fas fa-external-link-alt"></i>.  </a>`,
                `If you had income in {{ textProps.year }}, have your income forms, such as W-2 forms, 1042-S and/or 1099 forms, ready. If you have not received all your income forms, do not begin filing. If you are still missing an income form after February 15th, contact the payer of your income.`,
                `If you took a student loan for which you paid interest in {{ textProps.year }} be sure to have your student loan interest payment statement in hand. If you received your loan from a U.S. lender, you should receive form 1098-E. If you took your loan from a foreign lender, look for your interest payment statement.`,
            ],
        };
    },
    created() {
        var checklist = this.checklist.slice();

        for (var i = 0; i < checklist.length; i++) {
            checklist[i] = `<span>${checklist[i]}</span>`;
        }

        this.checklist = checklist;
    },
    props: ['year'],
    mounted() {
        AOS.init({
            once: true,
        });
    },
};
</script>
