<template>
    <li @click="$emit('click')" class="hs-sidebar-item">
        <div
            :class="getLinkClass"
            class="hs-sidebar-link row m-0"
            @click="goto"
        >
            <div class="hs-sidebar-link-icon p-0 m-0">
                <slot name="icon"></slot>
            </div>
            <div class="p-0 m-0">
                <slot> </slot>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        to: String,
    },
    methods: {
        goto() {
            if (!this.to) return;

            if (this.$route.name === this.to) return;
            this.$router.push({ name: this.to });
        },
    },
    computed: {
        getLinkClass() {
            var classes = [];
            if (this.$route.name === this.to) {
                classes.push(`active`);
            }
            return classes;
        },
    },
};
</script>
