<template>
    <div class="row m-0 sidebar-view">
        <btn
            @click.stop="expanded = !expanded"
            class="btn-icon sidebar-expand-btn btn-outline-primary"
            v-if="!expanded"
            color=""
            ><i class="fas fa-bars" />
        </btn>
        <nav
            class="hs-sidebar"
            :class="{ expanded }"
            v-click-outside="collapseSidebar"
        >
            <div class="h-100">
                <div class="collapse navbar-collapse h-100">
                    <div class="w-100 h-100 position-relative">
                        <span class="hs-sidebar-heading">Dashboard</span>
                        <ul class="hs-sidebar-nav">
                            <sidebar-item to="Dashboard">
                                <template v-slot:icon>
                                    <i class="fas fa-file-invoice-dollar"> </i>
                                </template>
                                Start Filing
                            </sidebar-item>
                            <sidebar-item to="Account">
                                <template v-slot:icon>
                                    <i class="fas fa-user-circle"> </i>
                                </template>
                                Account
                            </sidebar-item>
                            <sidebar-item to="Referrals">
                                <template v-slot:icon>
                                    <i class="fas fa-money-bill-wave"> </i>
                                </template>
                                Refer a Friend
                            </sidebar-item>
                            <sidebar-item to="Support">
                                <template v-slot:icon>
                                    <mdi> question_answer </mdi>
                                </template>
                                Support
                            </sidebar-item>
                            <sidebar-item to="FAQDashboard">
                                <template v-slot:icon>
                                    <i class="fas fa-question"> </i>
                                </template>
                                FAQ
                            </sidebar-item>
                        </ul>
                        <ul class="hs-sidebar-nav hs-sidebar-bot">
                            <sidebar-item @click="logout">
                                <template v-slot:icon>
                                    <mdi> exit_to_app </mdi>
                                </template>
                                Sign Out
                            </sidebar-item>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        <div class="hs-sidebar-spacer"></div>
        <div class="hs-sidebar-view">
            <slot> </slot>
        </div>
    </div>
</template>

<script>
import sidebarItem from './sidebar_item';
export default {
    components: { sidebarItem },
    data() {
        return {
            expanded: false,
            counter: 0,
            items: [
                {
                    title: 'Start Filing',
                    to: 'Dashboard',
                },
                {
                    title: 'Account',
                    to: 'Account',
                },
                {
                    title: 'Refer a Friend',
                    to: 'Referrals',
                },
                {
                    title: 'Support',
                    to: 'Support',
                },
                {
                    title: 'FAQ',
                    to: 'FAQDashboard',
                },
            ],
        };
    },
    methods: {
        collapseSidebar() {
            this.expanded = false;
        },
        logout() {
            this.$router.push({ name: 'Home' });
        },
    },
};
</script>
